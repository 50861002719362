import { render, staticRenderFns } from "./Buttons.vue?vue&type=template&id=7d07b30a&scoped=true&"
import script from "./Buttons.vue?vue&type=script&lang=js&"
export * from "./Buttons.vue?vue&type=script&lang=js&"
import style0 from "./Buttons.vue?vue&type=style&index=0&id=7d07b30a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d07b30a",
  null
  
)

export default component.exports