export const LOCATIONS = [
  {
    name: 'Queens',
    special: 'Loan Shark'
  },
  {
    name: 'Manhattan',
    special: 'Bank'
  },
  {
    name: 'Staten Island',
    special: 'Doctor'
  },
  {
    name: 'The Bronx',
    special: 'Tailor'
  },
  {
    name: 'Brooklyn',
    special: 'Gun Shop'
  }
]
