<template>
  <section id="doctor" class="overlay">
    <div>
      <header>
        <span>Doctor Feelgood</span>
        <img src="@/assets/images/close.svg" @click="handleCloseButtonPress" />
      </header>
      <template v-if="needDoctor && canAffordDoctor">
        <div class="inner">
          <p>The doctor can heal you for ${{ doctorCost }}. No questions asked!</p>
        </div>
        <footer>
          <div>
            <button @click="handleHealthButtonPress" class="full-width">Get Healed</button>
          </div>
        </footer>
      </template>
      <template v-else>
        <div class="inner" v-if="needDoctor && !canAffordDoctor">
          <p>Your broke ass cannot afford to see the doctor right now.</p>
        </div>
        <div class="inner" v-else>
          <p>Your health is already at 100%, what do you expect the doctor to do?</p>
        </div>
        <footer>
          <div>
            <button @click="handleCloseButtonPress" class="full-width">Okay</button>
          </div>
        </footer>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Doctor',
  computed: {
    doctorCost() {
      return this.$store.getters.doctorCost;
    },
    canAffordDoctor() {
      return this.$store.getters.cash >= this.doctorCost;
    },
    needDoctor() {
      let health = this.$store.getters.health;
      return health < 100;
    }
  },
  methods: {
    handleCloseButtonPress: function() {
      this.$store.dispatch('hideOverlay');
    },
    handleHealthButtonPress: function() {
      this.$manager.getHealed();
      this.$store.dispatch('hideOverlay');
    }
  }
}
</script>

<style scoped>
</style>
