<template>
  <section id="loanShark" class="overlay">
    <div>
      <header>
        <span>Loan Shark</span>
        <img src="@/assets/images/close.svg" @click="handleCloseButtonPress" />
      </header>
      <div class="inner">
        <p>Do you want to borrow some cash? Interest compounds daily.</p>
        <p>You have ${{ cash }} and you owe ${{ debt }}.</p>
      </div>
      <footer>
        <button @click="handleBorrowButtonPress(2500)" class="full-width">Borrow $2500</button>
        <button @click="handleRepayButtonPress(repayAmount)" class="full-width" :disabled="!canRepayMoney">
          Repay ${{ repayAmount}}
        </button>
        <button class="full-width"
                @click="handleRepayButtonPress(debt)"
                :disabled="!canRepayTotalDebt"
                v-if="showRepayTotalButton">
          Repay ${{ debt }}
        </button>
      </footer>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LoanShark',
  methods: {
    handleCloseButtonPress: function() {
      this.$store.dispatch('hideOverlay');
    },
    handleRepayButtonPress: function(amount) {
      this.$store.commit('repayMoney', amount);
    },
    handleBorrowButtonPress: function(amount) {
      this.$store.commit('borrowMoney', amount);
    }
  },
  computed: {
    repayAmount() {
      if (this.$store.getters.debt < 500) {
        return this.$store.getters.debt;
      }
      else {
        return 500;
      }
    },
    cash() {
      return this.$store.getters.cash;
    },
    debt() {
      return this.$store.getters.debt;
    },
    canRepayMoney() {
      return this.$store.getters.debt > 0;
    },
    canRepayTotalDebt() {
      return this.$store.getters.debt <= this.$store.getters.cash;
    },
    showRepayTotalButton() {
      return this.$store.getters.debt > 500;
    }
  }
}
</script>

<style scoped>
</style>
